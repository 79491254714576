import { Link } from '@inertiajs/react'
import clsx from 'clsx'

interface FunnelCategory {
  name: string
  slug: string
}

type CategoryGridProps = {
  categories: FunnelCategory[]
  selectedCategoryIndex: number | null
  onCategorySelect: (index: number) => void
}

const CategoryGrid = ({
  categories,
  selectedCategoryIndex,
  onCategorySelect,
}: CategoryGridProps) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 overflow-auto'>
      {categories.map((category, index) => (
        <div
          key={index}
          onClick={() => onCategorySelect(index)}
          className={clsx(
            'py-[0.5rem] rounded-lg cursor-pointer text-light-grey transition-colors duration-300 ml-[1rem] p-[0.5rem]',
            {
              'bg-gray-200': selectedCategoryIndex === index,
              'hover:bg-gray-100': selectedCategoryIndex !== index,
            },
          )}
        >
          <Link
            href={`/categories/${category.slug}`}
            className='text-[1rem] leading-[1.75rem] w-full h-full flex items-center'
          >
            {category.name}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default CategoryGrid
