import HorizontalMarginsWrapper from '@/components/atoms/wrappers/HorizontalMarginsWrapper'
import BuyerGuide from '@/components/molecules/Category/BuyerGuide'
import NavbarSearch from '@/components/molecules/navbar/NavbarSearch'
import CategoryHeroSection from '@/components/molecules/category/CategoryHeroSection'
import Navbar from '@/components/organisms/layouts/Navbar'
import { Popover } from '@headlessui/react'
import React, { useEffect, useState, useRef } from 'react'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import { useMobileDetect } from '@/utils/detectMobile'
import SolutionTicker from '@/components/organisms/Marketpalce/SolutionTicker'
import pocketShadow from '../../../assets/images/pocketshadow 1.svg'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import { FaFilter } from 'react-icons/fa6'
import { GoChevronRight } from "react-icons/go"
import { GoChevronLeft } from "react-icons/go"
import window from 'global'

import {
  sortOptionsMap,
} from '@components/atoms/constants/solutionOptions'
import Modal from '@components/atoms/modals/Modal'
import CategorySolutionCard from '@/components/molecules/categoryPage/CategorySolutionCard'
import axios from 'axios'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import ReactPaginate from 'react-paginate'
import SearchedSolutionsAndCategories from '../Solutions/SearchedSolutionsAndCategories'
import Button from '@/components/atoms/buttons/Button'
import CompareSolutionTab from '@/components/molecules/compareSolution/CompareSolutionTab'
import Input from '@/components/atoms/inputs/Input'
import { SortMenu } from '@/components/molecules/filterAndSort/SortMenu'
import HandleSearch from '@/utils/HandleSearch'
import IntegrationsDropdown from '@/components/molecules/categoryPage/IntegrationsDropdown'
import FeaturesDropdown from '@/components/molecules/categoryPage/FeaturesDropdown'
import HasPricingDropdown from '@/components/molecules/categoryPage/HasPricingDropdown'
import { RatingFilter } from '@/components/molecules/filterAndSort/RatingFilter'
import Meta from '@/components/atoms/widgets/Meta'
import LearnMoreSection from '@/components/molecules/Category/LearnMoreSection'
import useComparedSolutions from '@/hooks/useComparedSolutions'
import { DEFAULT_FILTER, REQUEST_PROPOSAL_CATEGORY_URL } from '@/utils/constants'
import { CompanySizeDropdown } from '@/components/molecules/categoryPage/CompanySizeDropdown'

import { openTallyChatbot } from '@/utils/helperFunctions'
import logo from 'app/assets/images/logo.svg'
import { BuyerGuideContent } from '@/components/molecules/Category/BuyerGuideContent'
import FastTrackPromo from '@/components/atoms/solution/FastTrackPromo'
import HybridLink from '@/components/atoms/widgets/HybridLink'
type CategoryShowProps = {
  isCurated?: boolean,
  category: schema.Category,
  parent_category?: string | null,
  categorySolutions: schema.Solution[],
  integrations: string[] | null,
  features: string[] | null,
  master_features: string[] | null,
  featured_solutions: schema.Solution[]
  media_details: {
    media_url: string
    media_type: string
    file_name: string
  },
  learnMoreLinks: []
  hideLearnMore: boolean
}

const CategoryShow = ({
  isCurated = false,
  category,
  parent_category,
  categorySolutions,
  media_details,
  integrations,
  featured_solutions,
  learnMoreLinks,
  hideLearnMore,
  features,
  master_features
}: CategoryShowProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [searchQuery, setSearchQuery] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [solutionsToCompare, setSolutionsToCompare] = useComparedSolutions()
  const [enterPressed, setEnterPressed] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  const [solutionsToShow, setSolutionsToShow] = useState([])
  const [searchedSolutions, setSearchedSolutions] = useState([])
  const [currentPagination, setcurrentPagination] = useState([])
  const [selectedIntegrations, setSelectedIntegrations] = useState([])
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [hasPricing, setHasPricing] = useState('')
  const [selectedCompanySize, setSelectedCompanySize] = useState([])
  const [filterCount, setFilterCount] = useState(0)
  const [page, setPage] = useState(1)
  const isInitialRender = useRef(true)
  const targetRef = useRef(null)

  const scrollToComponent = () => {
    const navbarHeight = document.querySelector('#navbar').offsetHeight;
    const top = targetRef.current.getBoundingClientRect().top + scrollY - navbarHeight;
    window.scrollTo({ top, behavior: 'smooth' });
  };

  const calculateFilterCount = () => {
    const featuresCount = selectedFeatures.length
    const integrationsCount = selectedIntegrations.length
    const pricingCount = hasPricing ? 1 : 0
    const ratingCount = filter.rating_gteq ? 1 : 0
    const companySizeCount = selectedCompanySize.length

    return featuresCount + integrationsCount + pricingCount + ratingCount + companySizeCount
  }

  const clearAllFilters = () => {
    setSelectedFeatures([])
    setSelectedIntegrations([])
    setHasPricing(null)
    setSelectedCompanySize([])
    setFilter(DEFAULT_FILTER)
  }
  useEffect(() => {
    const count = calculateFilterCount()
    setFilterCount(count)
  }, [selectedFeatures, selectedCompanySize, selectedIntegrations, hasPricing, filter])

  const handleReload = async () => {
    const requestData = {
      q: {
        name_i_cont: '',
        claims_status_matches_any: filter?.claimed ? 'approved' : '',
        rating_gteq: filter?.rating_gteq || null,
        searchQuery: searchQuery || null,
      },
      order_by: filter?.sort,
      integrations: selectedIntegrations,
      features: selectedFeatures,
      pricing: hasPricing,
      size: selectedCompanySize
    }

    if (page !== 1) {
      Object.assign(requestData, { page: page })
    }

    const url = isCurated
      ? `/curated_categories/${category.id}/filter_category_solutions`
      : `/categories/${category.id}/filter_category_solutions`

    const response = await axios.get(url, {
      headers: {
        Accept: 'application/json',
      },
      params: requestData,
    })

    setSolutionsToShow(response?.data?.solutions)
    setcurrentPagination(response.data.pagination)
    setPage(response.data.pagination.current_page)
  }

  const prevSearchQuery = useRef()
  const handlePopState = () => setSearchQuery('')
  const handleBeforeUnload = () => setSearchQuery('')

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    const debouncedReload = debounce(() => {
      handleReload()
    }, 300)

    if (prevSearchQuery.current !== searchQuery) {
      if (searchQuery.length >= 2 || searchQuery.length === 0) {
        debouncedReload()
      }
    } else {
      handleReload()
    }

    prevSearchQuery.current = searchQuery

    return () => {
      window.removeEventListener('popstate', handlePopState)
      window.removeEventListener('beforeunload', handleBeforeUnload)

      debouncedReload.cancel()
    }
  }, [filter, enterPressed ? searchQuery : null, enterPressed, selectedCompanySize, selectedIntegrations, selectedFeatures, hasPricing])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      handleReload()
    }
  }, [page])

  useEffect(() => {
    const timer = setTimeout(() => {
      const dropdownHeadings = document.querySelectorAll('.dropdown-heading')
      const dropdownContainers = document.querySelectorAll('.dropdown-container')

      dropdownHeadings.forEach(element => {
        element.classList.remove('dropdown-heading')
        element.classList.add('dropdown-h')
      })

      dropdownContainers.forEach(element => {
        element.style.setProperty('border', 'none', 'important')
        element.style.setProperty('border-radius', '4px', 'important')
      })
    }, 30)

    return () => clearTimeout(timer)
  }, [openModal])

  useEffect(() => {
    clearAllFilters()
  }, [category])

  const handleChange = (e) => {
    if (e.target.value.trim() === '') {
      setSearchResultsOpen(false)
      setSearchedSolutions([])
      setSearchQuery('')
      setEnterPressed(false)
    } else {
      setSearchResultsOpen(true)
      HandleSearch({
        event: e,
        allSolutions: categorySolutions,
        allCategories: [],
        searchTerm: searchQuery,
        setSearchTerm: setSearchQuery,
        setSolutionSearchResults: setSearchedSolutions,
        setCategorySearchResults: null,
      })
      setSearchResultsOpen(searchQuery !== '')
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setEnterPressed(true)
  }

  const isMobile = useMobileDetect()
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <PageTitle title={`Find ${category?.name?.includes('Tools') ? category?.name : `${category?.name} Tools`} on the Talivity Marketplace`} />
      <Meta
        title={`Find ${category?.name?.includes('Tools') ? category?.name : `${category?.name} Tools`} on the Talivity Marketplace`}
        description={`Discover top recruitment marketing solutions in ${category?.name} on Talivity Marketplace, formerly RecruitmentMarketing.com.`}
      />
      <div
        className={`flex w-full flex-col ${windowWidth < 1700 ? 'bg-cover bg-origin-content' : 'bg-contain  bg-no-repeat'} bg-center h-auto pt-[52px] sm:pt-[78px] object-scale-down`}
      >
        <Popover className='flex flex-col z-50'>
          {({ open }) => <Navbar open={open} />}
        </Popover>
        {
          isMobile &&
          <div className='my-10'>
            <NavbarSearch />
          </div>
        }
      </div>
      <div className='bg-[linear-gradient(170.84deg,#3FC8A9_4.13%,#2679A5_110.72%)]'>
        <HorizontalMarginsWrapper>
          <CategoryHeroSection
            category={category}
            parent_category={parent_category}
            scrollToComponent={scrollToComponent}
          />
        </HorizontalMarginsWrapper>
      </div>
      {!category.hide_buyers_guide && (
        <HorizontalMarginsWrapper>
          <BuyerGuide category={category} mediaDetails={media_details} />
        </HorizontalMarginsWrapper>
      )}
      {
        featured_solutions?.length > 0 &&
        <>
          <img src={pocketShadow} alt="shadow-effect" className='mb-8' />
          <HorizontalMarginsWrapper>
            <div>
              <h2 className='text-[28px] lg:text-[38px] text-center font-bold mb-8'>Solutions Spotlight</h2>
              <SolutionTicker solutions={featured_solutions} categoryPageCarousel={true} />
            </div>
          </HorizontalMarginsWrapper>
          <img src={pocketShadow} alt="shadow-effect" className='my-8' />
        </>
      }
      <div ref={targetRef} className='mb-10'>
        <HorizontalMarginsWrapper>
          <div className='flex flex-col gap-3 px-5 pt-5'>
            <p>
              <h2 className='inline-block text-[28px] sm:text-[38px] font-extrabold'>Explore Solutions</h2>
              <span className='text-[14px] text-gray-600'> ({currentPagination?.total_count})</span>
            </p>
            <div className='flex max-lg:flex-col gap-3'>
              <div className='flex justify-between w-full'>
                <div>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e)
                    }}
                  >
                    <Input
                      type='text'
                      placeholder='Search by solution name'
                      search={true}
                      value={searchQuery}
                      onChange={(e) => handleChange(e)}
                      searchIconClasses=' !left-[5.5px] !bottom-[3px] !mb-[0px] !size-4'
                      className='text-[14px] !pl-[26px] !py-[3px] sm:!py-[0px] !rounded-[4px] !pr-5'
                    >
                      {searchedSolutions.length > 0 &&
                        searchResultsOpen &&
                        !enterPressed && (
                          <SearchedSolutionsAndCategories
                            wrapperClassName={clsx(
                              'relative left-0 top-[0px] z-50',
                              solutionsToShow.length > 5 && 'max-h-[250px]'
                            )}
                            searchedSolutions={searchedSolutions}
                            searchedCategories={[]}
                            searchTerm={searchQuery}
                            onClose={() => {
                              setSearchResultsOpen(false)
                            }}
                            open={searchResultsOpen}
                          />
                        )}
                    </Input>
                  </form>
                </div>
                <div
                  className='flex bg-dark-gray rounded p-2 sm:hidden'
                  onClick={() => {
                    setOpenModal(true)
                  }}
                >
                  <FaFilter className='text-gray-600' />
                </div>

              </div>
              <div className='sm:flex justify-between w-full hidden'>
                <div>
                  <div className='hidden md-xl:flex gap-2'>
                    <RatingFilter
                      initialValue={filter.rating_gteq}
                      handleChange={(value: number) => {
                        setFilter({
                          ...filter,
                          rating_gteq: value,
                        })
                      }
                      }
                    />
                    <HasPricingDropdown
                      hasPricing={hasPricing}
                      setHasPricing={setHasPricing}
                    />
                    {integrations.length > 0 &&
                      <IntegrationsDropdown
                        integrations={integrations}
                        selectedIntegrations={selectedIntegrations}
                        setSelectedIntegrations={setSelectedIntegrations}
                      />
                    }
                    <FeaturesDropdown
                      features={features}
                      masterFeatures={master_features}
                      selectedFeatures={selectedFeatures}
                      setSelectedFeatures={setSelectedFeatures}
                    />
                    <CompanySizeDropdown
                      selectedCompanySize={selectedCompanySize}
                      setSelectedCompanySize={setSelectedCompanySize} />
                  </div>
                  <div
                    className='hidden items-center gap-2 rounded-[4px] bg-dark-gray sm:mr-[8.75rem] sm:flex sm:px-2 sm:py-[2.5px] md:mr-[12.5rem] md-xl:hidden'
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    <p className='hidden text-[14px] text-gray-600 sm:flex'>Filters</p>
                    <FaFilter className='text-gray-600' />
                  </div>
                </div>
                <div className='hidden items-center gap-4 sm:flex'>
                  <SortMenu
                    options={Object.entries(sortOptionsMap).map(([label, value]) => ({
                      label,
                      value,
                    }))}
                    onChange={(value) => {
                      setFilter({
                        ...filter,
                        sort: value,
                      })
                    }}
                    selected={filter.sort as string}
                  />
                </div>
              </div>
            </div>
            {filterCount > 0 &&
              <div className='flex font-medium text-black text-sm gap-1 mb-4'>
                <p className=''> {filterCount}</p>
                <p className=''>{filterCount === 1 ? 'filter' : 'filters'} selected - </p>
                <a className='text-ocean-blue underline cursor-pointer' onClick={() => clearAllFilters()}>Clear all</a>
              </div>
            }
            <div className='flex flex-col gap-4'>
              {solutionsToShow?.length > 0 && (
                <div
                  className='grid grid-cols-1 gap-6'
                >
                  {solutionsToShow.map((solution, index) => (
                    <>
                      <CategorySolutionCard
                        solution={solution}
                        key={solution.name}
                        solutionsToCompare={solutionsToCompare}
                        setSolutionsToCompare={setSolutionsToCompare}
                      />

                      {index === 2 && (
                        <HybridLink href={REQUEST_PROPOSAL_CATEGORY_URL}>
                          <FastTrackPromo />
                        </HybridLink>
                      )}
                    </>
                  ))}

                </div>
              )}
              {solutionsToShow?.length > 0 && (
                <div className='flex justify-between'>
                  <span className="text-gray-600">
                    Showing {solutionsToShow.length} of {categorySolutions.length}
                  </span>
                  <ReactPaginate
                    pageCount={currentPagination?.total_pages}
                    pageRangeDisplayed={6}
                    marginPagesDisplayed={1}
                    previousLabel={<GoChevronLeft className="text-gray-600" />}
                    nextLabel={<GoChevronRight className="text-gray-600" />}
                    breakLabel='...'
                    onPageChange={(event) => {
                      setPage(event.selected + 1)
                    }}
                    forcePage={page - 1}
                    containerClassName='flex justify-between items-center self-stretch'
                    pageClassName='flex px-1 text-gray-600'
                    activeClassName='text-[#2679A5] text-lg font-bold'
                  />
                </div>
              )}
            </div>
            
            {solutionsToShow.length === 0 && filterCount > 0 && (
              <div className='flex items-start gap-[32px]'>
                <img
                  src={logo}
                  alt='Logo'
                  className='w-[72px] h-[67px] flex-shrink-0 mr-[24px]'
                />
                <div className='flex flex-col items-start'>
                  <p className='w-[220px] sm:w-auto text-outer-space text-xl font-semibold leading-[32px] tracking-[0.15px] font-sans'>
                    No Matching Solutions Found
                  </p>
                  <p className='mt-2 text-base text-gray-600 w-full sm:w-[75%] lg:w-[64%] leading-[21.45px] tracking-[0.17px] font-sans'>
                    It seems no solutions match your criteria. You can adjust
                    the filters or ask Tally, our assistant, for help finding
                    what you need.
                  </p>
                  <div className='flex space-x-4 mt-[24px] '>
                    <Button
                      variant='teal'
                      className='flex h-[40px] w-[96px] text-[15px] items-center justify-center !text-dark-blue-tint-1 py-[3px] font-medium bg-talivity-teal rounded-[8px] sm:py-[6px] sm:px-[16px] sm:gap-[8px] sm:justify-center sm:items-center'
                      onClick={() => openTallyChatbot()}
                    >
                      Ask Tally
                    </Button>
                    <Button
                      variant='teal'
                      className='flex h-[40px] w-[121px] text-[15px] items-center justify-center !text-dark-blue-tint-1 py-[3px] font-medium bg-transparent rounded-[8px] border border-[#dcdcdc] bg-white sm:py-[6px] sm:px-[16px] sm:gap-[8px] sm:justify-center sm:items-center'
                      onClick={() => clearAllFilters()}
                    >
                      Clear Filters
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <Modal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              className='mx-4 max-w-[600px] rounded-xl bg-white p-10'
            >
              <div className='max-h-screen rounded-t-3xl bg-white px-4 py-8'>
                <h3 className='text-2xl font-bold leading-tight'>
                  Filters and Sort
                </h3>
                {filterCount > 0 &&
                  <div className='mb-7 font-medium text-black text-sm flex gap-1'>
                    <p> {filterCount} </p>
                    <p className=''>{filterCount === 1 ? 'filter' : 'filters'} selected - </p>
                    <a className='text-ocean-blue underline cursor-pointer' onClick={() => clearAllFilters()}>Clear all</a>
                  </div>
                }
                <div className='flex flex-col gap-2'>
                  <RatingFilter
                    initialValue={filter.rating_gteq}
                    handleChange={(value: number) => {
                      setFilter({
                        ...filter,
                        rating_gteq: value,
                      })
                    }
                    }
                  />
                  <div className='flex flex-col sm:hidden'>
                    <SortMenu
                      options={Object.entries(sortOptionsMap).map(([label, value]) => ({
                        label,
                        value,
                      }))}
                      onChange={(value) => {
                        setFilter({
                          ...filter,
                          sort: value,
                        })
                      }}
                      selected={filter.sort as string}
                    />
                  </div>
                  <IntegrationsDropdown
                    integrations={integrations}
                    selectedIntegrations={selectedIntegrations}
                    setSelectedIntegrations={setSelectedIntegrations}
                  />
                  <HasPricingDropdown
                    hasPricing={hasPricing}
                    setHasPricing={setHasPricing}
                  />
                  <FeaturesDropdown
                    features={features}
                    masterFeatures={master_features}
                    selectedFeatures={selectedFeatures}
                    setSelectedFeatures={setSelectedFeatures}
                  />
                  <CompanySizeDropdown
                    selectedCompanySize={selectedCompanySize}
                    setSelectedCompanySize={setSelectedCompanySize}
                  />
                </div>
                <div className='mt-4 flex flex-col gap-4' id='filterSortActions'>
                  <Button
                    size='md'
                    variant='primary'
                    onClick={() => setOpenModal(false)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </HorizontalMarginsWrapper>
      </div>

      {category.name === 'Applicant Tracking Systems (ATS)' && (
      <HorizontalMarginsWrapper classes='mb-16'>
        <BuyerGuideContent />
      </HorizontalMarginsWrapper>
      )}
      {

        (!hideLearnMore && learnMoreLinks?.length > 0) && (
          <HorizontalMarginsWrapper>
            <LearnMoreSection
              links={learnMoreLinks}
              categoryName={category?.name}
            />
          </HorizontalMarginsWrapper>
        )
      }
      {solutionsToCompare?.length > 0 && (
        <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
          <CompareSolutionTab
            solutionsToCompare={solutionsToCompare}
            setSolutionsToCompare={setSolutionsToCompare}
          />
        </div>
      )}
      <FooterSection />
    </>
  )
}

export default CategoryShow
