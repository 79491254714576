import { MultiSelect } from 'react-multi-select-component'

import './styles.css'

type FeaturesDropdownProps = {
  features: string[]
  masterFeatures: string[]
  selectedFeatures: string[]
  setSelectedFeatures: React.Dispatch<React.SetStateAction<string[]>>
}

const FeaturesDropdown = ({ features, masterFeatures, selectedFeatures, setSelectedFeatures }: FeaturesDropdownProps) => {
  const featureOptions = [
    ...masterFeatures?.map((feature) => ({
      label: feature,
      value: feature,
    })),
    { isDivider: true },
    ...features.map((feature) => ({
      label: feature,
      value: feature,
    }))
  ]

  return (
    <div className='relative cursor-pointer'>
      {selectedFeatures.length > 0 &&
        <span className='absolute top-[-3px] -right-1 flex size-3 rounded-full bg-ocean-blue z-10' />
      }
      <MultiSelect
        options={featureOptions}
        value={selectedFeatures}
        onChange={setSelectedFeatures}
        labelledBy="Features"
        valueRenderer={() => "Features"}
        overrideStrings={{
          selectSomeItems: "Features"
        }}
        className='text-[12px]'
        ClearSelectedIcon={null}
        hasSelectAll={false}
        ItemRenderer={({ option, onClick, disabled, checked }) => (
          option.isDivider ?
          <hr /> :
          <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
            <input
              type="checkbox"
              onChange={onClick}
              checked={checked}
              tabIndex={-1}
              disabled={disabled}
            />
            <span>{option.label}</span>
          </div>
        )}
      />
    </div>
  )
}

export default FeaturesDropdown
