import React, { useState, useEffect, useRef } from 'react'

import { useTracking } from 'react-tracking'

import { useDebounce } from '@/hooks/useDebounce'

import { IoSearch } from 'react-icons/io5'

import { BsSearch } from "react-icons/bs"

import axios from 'axios'

import SearchDropdown from '@/components/molecules/homepageSearch/SearchDropdown'
import useOutsideClick from '@/hooks/useOutsideClick'

const NavbarSearch = ({ hideDropdown, setHideDropdown, errorPage } : {hideDropdown?: boolean, setHideDropdown?: React.Dispatch<React.SetStateAction<boolean>>, errorPage?: boolean} ) => {
  const DEBOUNCE_DELAY = 2000
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)
  const trackedQuery = useDebounce(query, DEBOUNCE_DELAY)
  const [results, setResults] = useState({ solutions: [], articles: [], events: [], categories: [] })
  const [showDropdown, setShowDropdown] = useState(false)
  const tracking = useTracking()

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('/search', { params: { query: debouncedQuery } })
        setResults(response.data)
        setShowDropdown(true)
      } catch (error) {
        // Error Handling Here
      }
    }
    if (debouncedQuery) {
      fetchSearchResults()
    } else {
      setResults({ solutions: [], articles: [], events: [], categories: [] })
      setShowDropdown(false)
    }
  }, [debouncedQuery])

  useEffect(()=>{
    if (trackedQuery === '') return
    
    tracking.trackEvent({
      event_name: 'Homepage Search',
      page_url: window.location.pathname,
      event_loggable_type: 'RmcEvents::SearchLog',
      additional_attributes: { searched_term: trackedQuery }
    })
  }, [trackedQuery])

  useEffect(()=>{
    if (hideDropdown) {
      setShowDropdown(false)
      setQuery('')
    }
  },[hideDropdown])

  const closeSearchResults = () => {
    setShowDropdown(false)
  }

  const openSearchResults = () => {
    if(query)
    {
      setShowDropdown(true)
    }
  }

  const ref = useRef()
  useOutsideClick(ref, showDropdown, closeSearchResults)

  const handleChange = (e) => {
    setQuery(e.target.value)
    setHideDropdown(false)
  }

  const handleSelectResult = (result) => {
    let visit_info = null
    const pathMap = {
      'Solution': '/solutions/',
      'Category': '/categories/'
    }
    visit_info = pathMap[result.model_name] ? `${pathMap[result.model_name]}${result.slug}` : result.page_url
    if (visit_info) {
      tracking.trackEvent({
        event_name: 'Homepage Search',
        page_url: window.location.pathname,
        event_loggable_type: 'RmcEvents::SearchLog',
        additional_attributes: { searched_term: trackedQuery, clicked_url: visit_info }
      })
      window.open(visit_info, '_blank')
    }
  }

  return (
    <div
      className='relative flex justify-center rounded-lg' ref={ref}>
        <div className='block'>
          <div className='relative flex items-center'>
            <input className={errorPage? `h-[2.5rem] w-[325px] rounded-full border-[2px] border-[#D9D9D9] pl-10 text-md placeholder-[#747474] !outline-none
            drop-shadow-md focus:border-none hover:cursor-text sm:h-[2.776rem] sm:drop-shadow-none lg:!w-[29.5rem] xxs:w-[24rem]`: `h-[2.5rem] w-[20rem] rounded-lg border-gray-300 pl-10 text-xs !outline-none drop-shadow-md
            placeholder:text-gray-300 focus:border-none hover:cursor-text sm:h-[2rem] sm:rounded-full sm:drop-shadow-none md:w-[18rem]` }
              id='search-navbar'
              type='text'
              placeholder={'Search The Marketplace'}
              value={query} onChange={handleChange}
              onClick={openSearchResults}
            />
            {errorPage? <IoSearch  color='#747474' className='absolute left-3 size-[24px]'/> : <BsSearch className='absolute left-2 size-[17px] text-gray-300' /> }
          </div>
          {(!hideDropdown && showDropdown) && (
            <div className='absolute z-40 max-h-96 w-[20rem] overflow-y-scroll rounded-lg bg-white p-5 md:h-[20rem] md:w-[18rem]'>
              <div className='dropdown-menu text-left'>
                <div className='dropdown-content'>
                  <SearchDropdown results={results.solutions} heading={'Solutions'} handleSelectResult={handleSelectResult} />
                  <div className='my-2 border-t border-gray-200' />
                  <SearchDropdown results={results.categories} heading={'Categories'} url={'/marketplace'} handleSelectResult={handleSelectResult} />
                  <div className='my-2 border-t border-gray-200' />
                  <SearchDropdown results={results.articles} heading={'Articles'} url={'/topics'} handleSelectResult={handleSelectResult} />
                  <div className='my-2 border-t border-gray-200' />
                  <SearchDropdown results={results.events} heading={'Events'} url={'/events'} handleSelectResult={handleSelectResult} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  )
}

export default NavbarSearch
