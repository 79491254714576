import clsx from 'clsx'

type DesktopStageSelectProps =  {
  stages: string[]
  selectedStage: string
  onSelectStage: (stage: string) => void
}

const DesktopStageSelect = ({
  stages,
  selectedStage,
  onSelectStage,
}: DesktopStageSelectProps) => {
  return (
   <div className='top-[7.5rem] flex min-h-[35.27rem] w-[22.875rem] flex-col bg-maastricht-blue text-white rounded-tl-lg rounded-bl-lg'>
      <h2 className='flex flex-start p-[1rem] text-white text-[0.75rem] font-normal uppercase leading-[2rem]'>
        Select Stage:
      </h2>
      <div className='px-[0.5rem]'>
        {stages.map((stage, index) => (
          <p
            key={index}
            onClick={() => onSelectStage(stage)}
            className={clsx(
              'ml-[1rem] flex cursor-pointer items-center border-b-[0.0625rem] border-r-[0.125rem] border-cyan pb-[1rem] pl-[1rem] pr-[0.75rem] pt-[1.25rem] text-[1rem]',
              {
                'border-b-ship-grey font-bold': selectedStage === stage,
                'border-b-ship-grey border-r-transparent':
                  selectedStage !== stage,
              },
            )}
          >
            {stage}
          </p>
        ))}
      </div>
    </div>
  )
}

export default DesktopStageSelect
