import { useState } from 'react'
import { MdOutlineVerified as VerifiedIcon } from 'react-icons/md'

export const ClaimStatus = ({ isClaimed }) => {
  const [toggleClaimInfo, setToggleClaimInfo] = useState(false)

  return (
    <div className={'flex gap-[2px]'}>
      <button
        className="flex relative text-left items-center"
        onClick={() => setToggleClaimInfo((prev) => !prev)}
        aria-expanded={toggleClaimInfo}
      >
        <div className='flex flex-row items-center gap-1'>
          {isClaimed ? (
            <>
              <VerifiedIcon className='text-teal' size={16} />
              <p
                className={`text-[9px] sm:text-sm text-teal ${!isClaimed && 'cursor-pointer'}`}
              >
                Verified
              </p>
            </>
          ) : (
            <p
            className={`text-[9px] sm:text-sm text-dark-gray ${!isClaimed && 'cursor-pointer'}`}
          >
            Unverified
          </p>
          )}
        </div>
      </button>
    </div>
  )
}
