import { Link } from '@inertiajs/react'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import { FaArrowRight } from 'react-icons/fa6'

type ActionButtonsProps = {
  addSolutionUrl: string
}

const ActionButtons = ({ addSolutionUrl }: ActionButtonsProps) => {
  return (
    <div className='mt-auto flex justify-between border-t border-gray-300 pt-[1rem]'>
      <HybridLink href={addSolutionUrl}>
        <button
          className='min-w-[134px] h-[30px] text-[13px] px-3 py-1 bg-talivity-teal text-black rounded-lg flex items-center justify-center'
        >
          Add Your Solution
        </button>
      </HybridLink>

      <Link href='/marketplace'>
        <button
          className='min-w-[134px] h-[30px] text-[13px] flex items-center justify-center px-3 py-1 rounded-lg hover:bg-gray-200 text-black'
        >
          Visit The Marketplace
          <FaArrowRight className='ml-2' />
        </button>
      </Link>
    </div>
  )
}

export default ActionButtons
