import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'
import { FiSearch } from "react-icons/fi"
import { useTracking } from 'react-tracking'

import SearchDropdown from '@/components/molecules/homepageSearch/SearchDropdown'
import { useDebounce } from '@/hooks/useDebounce'
import useOutsideClick from '@/hooks/useOutsideClick'

import { MdOutlineClose } from "react-icons/md";

const HomepageSearch = () => {
  const DEBOUNCE_DELAY = 2000
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query)
  const trackedQuery = useDebounce(query, DEBOUNCE_DELAY)
  const [results, setResults] = useState({ solutions: [], articles: [], events: [], categories: [] })
  const [showDropdown, setShowDropdown] = useState(false)
  const tracking = useTracking()
  const sections = [
    { results: results.solutions, heading: 'Solutions' },
    { results: results.categories, heading: 'Categories', url: '/marketplace' },
    { results: results.articles, heading: 'Articles', url: '/topics' },
    { results: results.events, heading: 'Events', url: '/events' }
  ]

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('/search', { params: { query: debouncedQuery } })
        setResults(response.data)
        setShowDropdown(true)
      } catch (error) {
        // Error Handling Here
      }
    }
    if (debouncedQuery) {
      fetchSearchResults()
    } else {
      setResults({ solutions: [], articles: [], events: [], categories: [] })
      setShowDropdown(false)
    }
  }, [debouncedQuery])

  useEffect(()=>{
    if (trackedQuery === '') return
    
    tracking.trackEvent({
      event_name: 'Homepage Search',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::SearchLog",
      additional_attributes: { searched_term: trackedQuery }
    })
  }, [trackedQuery])

  const closeSearchResults = () => {
    setShowDropdown(false)
  }

  const ref = useRef()
  useOutsideClick(ref, showDropdown, closeSearchResults)

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const handleSelectResult = (result) => {
    let visit_info = null
    const pathMap = {
      'Solution': '/solutions/',
      'Category': '/categories/'
    }
    visit_info = pathMap[result.model_name] ? `${pathMap[result.model_name]}${result.slug}` : result.page_url
    if (visit_info) {
      tracking.trackEvent({
        event_name: 'Homepage Search',
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::SearchLog",
        additional_attributes: { searched_term: trackedQuery, clicked_url: visit_info }
      })
      window.open(visit_info, '_blank')
    }
  }

  const handleSearchDropdown = () => {
    setQuery('')
    setShowDropdown(false)
  }
  
  const openSearchResults = () => {
    if(query) setShowDropdown(true)
  }

  return (
    <div className='relative flex justify-center' ref={ref}>
      <div className='block'>
      <div className='relative flex items-center'>
      <input className='h-[3.25rem] w-[22rem] rounded-full border-none pl-14 font-normal !outline-none placeholder:text-gray-300 hover:cursor-text sm:w-[35rem] 2xs:w-[19rem]'
          id='search-bar-homepage'
          type='text' 
          placeholder='Search' 
          value={query} 
          onChange={handleChange}
          style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}
          onClick={openSearchResults}
        />
        <FiSearch className="absolute left-4 size-[24px]" color='#2E343A61'/>
        {showDropdown && <MdOutlineClose className='absolute right-2 size-6 text-gray-300 hover:cursor-pointer' onClick={handleSearchDropdown} />}
      </div>
        {showDropdown && (
          <div className='absolute z-10 max-h-60 w-[20rem] overflow-y-scroll rounded-lg bg-white p-5 md:h-[13rem] md:w-[35rem] lg:h-[15rem] xl:h-[14.5rem]'>
            <div className='dropdown-menu text-left'>
              <div className='dropdown-content'>
              {sections.map((section, index) => (
                <React.Fragment key={index}>
                  <SearchDropdown 
                    results={section.results} 
                    heading={section.heading} 
                    url={section.url}
                    handleSelectResult={handleSelectResult} 
                  />
                  {index < sections.length - 1 && <div className='my-2 border-t border-gray-200' />}
                </React.Fragment>
              ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomepageSearch
