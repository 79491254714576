import DesktopCategoriesSection from '@/components/molecules/navbar/DesktopCategoriesSection'
import MobileCategoriesSection from '@/components/molecules/navbar/MobileCategoriesSection'
import { addSolutionUrl } from '@/utils/constants'

type AllCategoriesSectionProps = {
  funnelCategories: Record<string, any>
  contentForModal: boolean
  setShowCategoriesDropdown: (value: boolean) => void
}

const AllCategoriesSection = ({
  funnelCategories,
  contentForModal,
  setShowCategoriesDropdown,
}: AllCategoriesSectionProps) => {
  return contentForModal ? (
    <MobileCategoriesSection
      funnelCategories={funnelCategories}
      contentForModal={contentForModal}
      setShowCategoriesDropdown={setShowCategoriesDropdown}
    />
  ) : (
    <DesktopCategoriesSection
      funnelCategories={funnelCategories}
      addSolutionUrl={addSolutionUrl}
    />
  )
}

export default AllCategoriesSection
