import { useState } from 'react'
import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import { Popover } from '@headlessui/react'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import { FaArrowRight } from 'react-icons/fa6'
import { addSolutionUrl } from '@/utils/constants'
import { IoIosArrowBack } from 'react-icons/io'

interface FunnelCategory {
  name: string
  slug: string
}

type MobileCategoriesSectionProps = {
  funnelCategories: Record<string, FunnelCategory[]>
  contentForModal: boolean
  setShowCategoriesDropdown: (value: boolean) => void
}

const MobileCategoriesSection = ({
  funnelCategories,
  contentForModal,
  setShowCategoriesDropdown,
}: MobileCategoriesSectionProps) => {
  const firstStage = Object.keys(funnelCategories)[0]
  const [selectedStage, setSelectedStage] = useState<string | null>(
    contentForModal ? null : firstStage,
  )
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<
    number | null
  >(null)

  const handleStageClick = (stage: string) => {
    setSelectedStage(stage)
  }

  const handleBackToMarketplace = () => {
    if (selectedStage) {
      setSelectedStage(null)
    } else {
      setShowCategoriesDropdown(false)
    }
  }

  return (
    <div className='relative top-[-1px] flex h-full w-full flex-col justify-between bg-white px-5 pt-3 sm:px-5 sm:py-6'>
      {selectedStage === null ? (
        <Popover.Button
          className='flex items-center mr-4'
          onClick={handleBackToMarketplace}
        >
          <span className='text-[17px] mr-4'>←</span>
          Back
        </Popover.Button>
      ) : (
        <button
          className='flex items-center mr-4'
          onClick={handleBackToMarketplace}
        >
          <span className='text-[17px] mr-4'>←</span>
          Back
        </button>
      )}

      <div className='border-t border-gray-300 my-2'></div>
      <div className='bg-white text-white  py-2 flex items-center'>
        <h2 className='text-teal  text-[0.75rem]'>
          {selectedStage ? `Marketplace / ${selectedStage}` : 'Marketplace'}
        </h2>
      </div>

      <div className='flex flex-col flex-1 bg-white h-full max-h-[700px]  '>
        {!selectedStage ? (
          <div className='max-h-[500px]  overflow-scroll'>
            {Object.keys(funnelCategories).map((stage, index) => (
              <p
                key={index}
                onClick={() => handleStageClick(stage)}
                className='cursor-pointer px-1 py-3 border-cyan flex justify-between items-center w-full text-light-grey text-[15px]'
              >
                <span>{stage}</span>
                <IoIosArrowBack className='text-[18px] rotate-180' />
              </p>
            ))}
          </div>
        ) : (
          <div className='max-h-[400px] overflow-scroll sm:max-h-[400px] md:max-h-[400px]'>
            {funnelCategories[selectedStage].map((obj, index) => (
              <div
                key={index}
                onClick={() => setSelectedCategoryIndex(index)}
                className={clsx(
                  'cursor-pointer  py-4 rounded-lg transition-colors duration-300',
                )}
              >
                <Link href={`/categories/${obj.slug}`} className='text-black text-[15px]'>
                  {obj.name}
                </Link>
              </div>
            ))}
          </div>
        )}
         <div className='mt-auto flex flex-col gap-2 border-t border-gray-300 pt-4'>
        <HybridLink href={addSolutionUrl}>
          <button className='w-full px-4 py-2 bg-talivity-teal text-black rounded-lg'>
            Add Your Solution
          </button>
        </HybridLink>

        <Link href='/marketplace'>
  <button className='flex items-center justify-between px-4 py-2 w-full rounded-lg hover:bg-gray-200 text-black mb-4 text-sm'>
    <span className='flex-grow'>Visit The Marketplace</span>
    <FaArrowRight className='ml-2' />
  </button>
</Link>



      </div>
      </div>
    </div>
  )
}

export default MobileCategoriesSection
