import React from 'react'
import Modal from '@/components/atoms/modals/Modal'
import Button from '@/components/atoms/buttons/Button'
import { MLP_PROMO_LINK } from '@/utils/constants'

interface PremiumFeaturesModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
}

const PremiumFeaturesModal: React.FC<PremiumFeaturesModalProps> = ({ openModal, setOpenModal }) => {
  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <Modal
      isOpen={openModal}
      onClose={handleClose}
      className='mx-4 w-[35.25rem] rounded-lg bg-white p-10 shadow-lg'
    >
      <h2 className='text-[2.125rem] leading-[2.835rem] tracking-[0.15px] font-medium text-gray-900'>
        Enable Premium Features
      </h2>

      <p className='mt-3 text-[0.875rem] leading-[1.374rem] tracking-[0.1px] text-gray-700'>
        Boost your visibility and discoverability with an SEO-powered premium
        profile with <strong>unlimited on-page leads</strong>
      </p>

      <ul className='mt-4 list-inside list-disc pl-4 text-gray-600 text-[1rem] leading-[1.8rem]'>
        <li>Unlimited Inbound Leads</li>
        <li>First Response Access To RFP FastTrack Requests</li>
        <li>Free Press Release Submissions</li>
        <li>SEO Powered Premium Profile</li>
        <li>Premium Enhanced Marketing Advisory Services</li>
      </ul>

      <Button
        type='button'
        variant='darkBlue'
        className='flex p-[0.4375rem] px-[1rem] justify-center items-center gap-[0.5rem] rounded-md mt-6'
        onClick={() => (window.location.href = MLP_PROMO_LINK)}
      >
        Upgrade Account
      </Button>
    </Modal>
  )
}

export default PremiumFeaturesModal
