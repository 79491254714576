import React, { useState } from 'react'
import DesktopStageSelect from './DesktopStageSelect'
import CategoryGrid from './CategoryGrid'
import ActionButtons from './ActionButtons'

interface FunnelCategory {
  name: string
  slug: string
}

type DesktopCategoriesSectionProps = {
  funnelCategories: Record<string, FunnelCategory[]>
  addSolutionUrl: string
}

const DesktopCategoriesSection = ({
  funnelCategories,
  addSolutionUrl,
}: DesktopCategoriesSectionProps) => {
  const firstStage = Object.keys(funnelCategories)[0]
  const [selectedStage, setSelectedStage] = useState<string>(firstStage)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<
    number | null
  >(null)

  return (
    <div className='flex flex-col xl:flex-row gap-[1.5rem] rounded-lg  '>
      <DesktopStageSelect
        stages={Object.keys(funnelCategories)}
        selectedStage={selectedStage}
        onSelectStage={setSelectedStage}
      />

      <div className='flex-1 p-[0.75rem] bg-white rounded-lg min-h-[35rem] w-[49.125rem] flex flex-col'>
        <h3 className='text-[0.75rem] mb-[0.5rem] text-blue-smoke  font-normal uppercase leading-[2rem]'>
          {selectedStage}
        </h3>

        <CategoryGrid
          categories={funnelCategories[selectedStage] || []}
          selectedCategoryIndex={selectedCategoryIndex}
          onCategorySelect={setSelectedCategoryIndex}
        />

        <ActionButtons addSolutionUrl={addSolutionUrl} />
      </div>
    </div>
  )
}

export default DesktopCategoriesSection
