import { lazy, useRef } from 'react'

import { Popover } from '@headlessui/react'

import { useInView } from '@/hooks/useInView'

import { useMobileDetect } from '@/utils/detectMobile'

import Navbar from '@/components/organisms/layouts/Navbar'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import MarketplaceHeroSection from '@/components/molecules/Marketplace/MarketplaceHeroSection'
import SolutionEvaluator from '@/components/organisms/Marketpalce/SolutionEvaluator'
import SolutionTicker from '@/components/organisms/Marketpalce/SolutionTicker'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import Meta from '@/components/atoms/widgets/Meta'
import useComparedSolutions from '@/hooks/useComparedSolutions'
import clsx from 'clsx'

const MarketplaceFunnelSection = lazy(() => import('@/components/molecules/Marketplace/MarketplaceFunnelSection'))
const PromoSection = lazy(() => import('@/components/organisms/Marketplace/PromoSection'))
const FooterSection = lazy(() => import('@/components/organisms/footerSection/FooterSection'))
const CompareSolutionTab  = lazy(() => import('@/components/molecules/compareSolution/CompareSolutionTab'))
const NavbarSearch = lazy(() => import('@components/molecules/navbar/NavbarSearch'))

type MarketplaceLandingPageProps = {
  paid_solutions: schema.Solution[]
  funnelCategories: schema.ParentCategory[]
}

const MarketplaceLandingPage = (
  { paid_solutions, funnelCategories }: MarketplaceLandingPageProps) => {

  const isMobile = useMobileDetect()
  const [solutionsToCompare, setSolutionsToCompare] = useComparedSolutions()

  const [promoInView] = useInView()
  const categoriesRef = useRef()

  return (
    <>
      <PageTitle title='Your Talent Acquisition Tech Hub | Talivity Marketplace' />
      <Meta 
        title='Your Talent Acquisition Tech Hub | Talivity Marketplace'
        description='Explore the top talent acquisition tech tools and solutions on the Talivity Marketplace, formerly RecruitmentMarketing.com.' 
      />
      <div
        className={clsx(
          'flex w-full flex-col h-auto pt-[52px] sm:pt-[78px]',
          !isMobile && 'bg-fade-gray-gradient'
        )}
      >
        <Popover className="flex flex-col z-50">
          {({ open }) => <Navbar open={open} />}
        </Popover>
        {
          isMobile &&
          <div className='mt-10'>
            <NavbarSearch />
          </div>
        }
        <MarketplaceHeroSection targetRef={categoriesRef} />
          <div className='relative top-[-15px] '>
            <SolutionTicker solutions={paid_solutions}/>
          </div>
          <HorizontalMarginsWrapper>
            <SolutionEvaluator/>
          </HorizontalMarginsWrapper>
      </div>
      
      <HorizontalMarginsWrapper>
        <div ref={categoriesRef}>
      <MarketplaceFunnelSection funnelCategories={funnelCategories}  />
      </div>
      {promoInView && <PromoSection />}
      </HorizontalMarginsWrapper>
      {solutionsToCompare?.length > 0 && (
              <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
                <CompareSolutionTab
                  solutionsToCompare={solutionsToCompare}
                  setSolutionsToCompare={setSolutionsToCompare}
                />
              </div>
            )}
      <FooterSection />
    </>
  )
}

export default MarketplaceLandingPage
